function set_player_width(){
    // Adjust width to column width
  $(".show_episode_player").width( $(".episode").width() );
}

$(window).on("resize", function(){set_player_width();});

// setup jwplayer
$(document).on("turbo:load", function()
{
  if ( $("#embed_player").length ) {	
    jwplayer("embed_player").setup(
    {
        width: "100%",
        height: 44,
        skin: "five",
        file: $(".show_episode_player").data("download-url"),
        ga: {}
    }).resize("100%", 44); // force resize hack required ¯\_(ツ)_/¯
    
    set_player_width();
    }       
});