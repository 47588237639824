//After http://www.jottings.com/obfuscator/

function set_email() { 
	if($('.hide-email').length){
		//console.log("setting email")

		coded = $('#obfuscator').data('coded');
		key = $('#obfuscator').data('key');
		
		shift=coded.length
		link=""
		for (i=0; i<coded.length; i++) {
		if (key.indexOf(coded.charAt(i))==-1) {
			ltr = coded.charAt(i)
			link += (ltr)
		}
		else {     
			ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length
			link += (key.charAt(ltr))
		}
		}
		//console.log("email: " + link)
		
		$('.hide-email').attr('href','mailto:' + link).html(link);
	}
	  
};

$(document).on('turbo:load', set_email() ) 
